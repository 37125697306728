'use strict';
// Avoid `console` errors in browsers that lack a console.
(function () {
    var method;
    var noop = function () { };
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());
/*
Error handling
*/
//IE8+
window.onerror = function (message, file, line) {
    var container = document.querySelector(".alert-js");
    if (container != null) {
        container.innerHTML = container.innerHTML + "<small>" + message + ", " + file + ", " + line + "</small>";
        container.style.display = "block";
    }
}

/*
Polyfill IE8
*/
String.prototype.trim = function () {
    return this.replace(/^\s+|\s+$/g, "");
}


String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

/*
Polyfill replaceWith - Safari, and IE > 10
*/
function ReplaceWithPolyfill() {
    'use-strict'; // For safari, and IE > 10
    var parent = this.parentNode, i = arguments.length, currentNode;
    if (!parent) return;
    if (!i) // if there are no arguments
        parent.removeChild(this);
    while (i--) { // i-- decrements i and returns the value of i before the decrement
        currentNode = arguments[i];
        if (typeof currentNode !== 'object') {
            currentNode = this.ownerDocument.createTextNode(currentNode);
        } else if (currentNode.parentNode) {
            currentNode.parentNode.removeChild(currentNode);
        }
        // the value of "i" below is after the decrement
        if (!i) // if currentNode is the first argument (currentNode === arguments[0])
            parent.replaceChild(currentNode, this);
        else // if currentNode isn't the first
            parent.insertBefore(this.previousSibling, currentNode);
    }
}
if (!Element.prototype.replaceWith)
    Element.prototype.replaceWith = ReplaceWithPolyfill;
if (!CharacterData.prototype.replaceWith)
    CharacterData.prototype.replaceWith = ReplaceWithPolyfill;
if (!DocumentType.prototype.replaceWith)
    DocumentType.prototype.replaceWith = ReplaceWithPolyfill;

/*
Utils
*/
var h = {};

//check if IE
h.isIE = /MSIE \d|Trident.*rv:/.test(navigator.userAgent);
h.isIE8 = navigator.userAgent.search("Trident/4.0") > -1;
h.isIE9 = navigator.userAgent.search("Trident/5.0") > -1;
h.isIE10 = navigator.userAgent.search("Trident/6.0") > -1;
h.isIE11 = navigator.userAgent.search("Trident/7.0") > -1;

//document.ready, including IE8+
h.ready = function (fn) {
    if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', fn);
    } else if (document.attachEvent) {

        document.attachEvent("onreadystatechange", function () {
            if (document.readyState === "complete") {
                fn();
            }
        });
    }
}


h.getJSON = function (url, callbackSuccess, callbackError) {

    var request = new XMLHttpRequest();
    request.open('GET', url, true);

    request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
            var data = JSON.parse(request.responseText);
            callbackSuccess(data);

        } else {
            callbackError();
        }
    };

    request.onerror = function () {
        callbackError();
    };
    request.send();
}


h.get = function (url, callback) {

    var localTest = /^(?:file):/;
    var xmlhttp = new XMLHttpRequest();
    var status = 0;

    xmlhttp.onreadystatechange = function () {
        /* if we are on a local protocol, and we have response text, we'll assume
    *  				things were sucessful */
        if (xmlhttp.readyState == 4) {
            status = xmlhttp.status;
        }
        if (localTest.test(location.href) && xmlhttp.responseText) {
            status = 200;
        }
        if (xmlhttp.readyState == 4 && status == 200) {

            var ret = xmlhttp.responseText;
            callback(ret);
        }
    }

    try {
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    } catch (err) {
        /* todo catch error */
    }

}

h.isInIframe = function() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}


h.matches = function (el, selector) {
    var p = Element.prototype;
    var f = p.matches || p.webkitMatchesSelector || p.mozMatchesSelector || p.msMatchesSelector || function (s) {
        return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
    };

    return f.call(el, selector);
}

h.delegateEvent = function (el, ev, selector, fn) {
    h.addEventListener(el, ev, function (e) {
       
        if (e.target && (h.matches(e.target, selector + ", " + selector + " *"))) {
            fn(e, e.target);
        } /*else if (e.target && e.target.parentNode && (h.matches(e.target.parentNode, selector))) {
            fn(e, e.target.parentNode);
        } else if (e.target && e.target.parentNode && e.target.parentNode.parentNode && (h.matches(e.target.parentNode.parentNode, selector))) {
            fn(e, e.target.parentNode.parentNode);
        }*/
        
    });
}

h.addEventListener = function (el, ev, fn) {
    if (el) {
        if (h.isIE8) {
            el.attachEvent(ev, fn);
            return;
        }
        el.addEventListener(ev, fn);
    }
}

h.forEach = function (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]); // passes back stuff we need
    }
};

h.addClass = function (el, className) {
    if (!el) return;
    if (el.classList)
        el.classList.add(className);
    else
        el.className += ' ' + className;
}

h.removeClass = function (el, className) {
    if (!el) return;
    if (el.classList)
        el.classList.remove(className);
    else
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
}

h.hasClass = function (el, className) {
    if (!el) return;
    if (el.classList) {
        return el.classList.contains(className);
    } else {
        return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
    }
}

h.toggleClass = function (el, className) {
    if (!el) return;
    if (h.hasClass(el, className)) {
        h.removeClass(el, className);
        return "";
    } else {
        h.addClass(el, className);
        return className;
    }
}

h.getCookie = function (sName) {
    var oCrumbles = document.cookie.split(';');
    for (var i = 0; i < oCrumbles.length; i++) {
        var oPair = oCrumbles[i].split('=');
        var sKey = decodeURIComponent(oPair[0].trim());
        var sValue = oPair.length > 1 ? oPair[1] : '';
        if (sKey == sName) {
            return decodeURIComponent(sValue);
        }
    }
    return '';
};

h.setCookie = function (sName, sValue, options) {

    var sCookie = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);
    // Shorthand: options === expires date
    if (options && options instanceof Date) {
        options = {
            expires: options
        };
    }
    // Longhand: options object
    if (options && typeof options == 'object') {
        if (options.expires) {
            sCookie += '; expires=' + options.expires.toGMTString();
        }
        if (options.path) {
            sCookie += '; path=' + options.path.toString();
        }
        if (options.domain) {
            sCookie += '; domain=' + options.domain.toString();
        }
        if (options.secure) {
            sCookie += '; secure';
        }
    }
    document.cookie = sCookie;
};

h.removeCookie = function (sName, options) {
    if (!options) {
        var options = {};
    }
    options.expires = new Date();
    h.setCookie(sName, '', options);
};

h.addScript = function (url) {
    var head = document.getElementsByTagName('head')[0];
    var js = document.createElement("script");
    js.src = url;
    head.appendChild(js);
}


h.getJsFilePath = function (filename) {
    var scriptElements = document.getElementsByTagName('script');
    for (var i = 0; i < scriptElements.length; i++) {
        var source = scriptElements[i].src;
        if (source.indexOf(filename) > -1) {
            var location = source.substring(0, source.indexOf(filename)) + filename;
            return location;
        }
    }
    return false;
}


/*
Interface
*/
var bsml = bsml || {};

bsml.properties = {
    closeOverlayOnClick: false
}

bsml.global = {
    window: document.querySelector('html'),
    header: document.querySelector('.page-header'),
    body: document.querySelector('body'),
    menu: document.querySelector(".menu"),
    page_content: document.querySelector('.page-content'),
    alert_system: document.querySelector(".alert-system"),
    is_menu_opened: h.hasClass(document.querySelector('body'), "menu-mobile-opened")
}

bsml.init = function () {

    bsml.applyStyleBasedOnQueryString(bsml.global.body, "remove-header=1", "remove-header");
    bsml.applyStyleBasedOnQueryString(bsml.global.body, "remove-menu=1", "remove-menu");
    bsml.applyStyleBasedOnQueryString(bsml.global.body, "remove-title=1", "remove-title");
    bsml.applyStyleBasedOnQueryString(bsml.global.body, "expand=1", "template-expand");
    bsml.applyStyleBasedOnQueryString(bsml.global.body, "embed=1", "embed");
    bsml.applyStyleBasedOnQueryString(bsml.global.body, "debug=1", "debug");
    bsml.applyStyleBasedOnQueryString(bsml.global.body, "trial=1", "trial");

    bsml.addExtraComponents(bsml.global.body, bsml.global.alert_system);
    bsml.controOfflineStatus(bsml.global.body);
    bsml.showGoToTop();
    bsml.setHideObjectOnScroll(bsml.global.header, "page-header-hide");

    bsml.setExpanded();
    bsml.setOpenCloseBoxes();
    bsml.resizeIframes();
    bsml.convertIcons();
    bsml.setAutosuggest();
    //h.addEventListener(document, 'smlNav', convertIcons);
    tsml.translate(bsml.global.body);

    h.delegateEvent(bsml.global.header, "click", ".btn-open-menu", bsml.toggleMenu);
    h.delegateEvent(bsml.global.header, "click", ".btn-contract", bsml.toggleExpand);
    h.delegateEvent(bsml.global.header, "click", ".btn-expand", bsml.toggleExpand);
    h.delegateEvent(bsml.global.header, "click", ".btn-expand-search", bsml.toggleSearch);

    h.delegateEvent(bsml.global.menu, "click", ".nav-link", bsml.toogleMenuLinkActive);
    h.delegateEvent(bsml.global.page_content, "click", ".box-open-and-close .box-header", bsml.toggleBox);
    h.delegateEvent(bsml.global.body, "click", ".alert .close", bsml.dismissAlert);

    h.delegateEvent(bsml.global.body, "click", "[data-toggle='alert']", bsml.notificate);

    h.delegateEvent(bsml.global.body, "click", ".app-overlay", bsml.closeOverlay);
    if ('ontouchstart' in document.documentElement) {
        bsml.global.body.style.height = "100%";
        h.delegateEvent(bsml.global.body, "touchstart", ".app-overlay", bsml.closeOverlay);
    }
}

bsml.notificate = function (e, el) {
    if (el) {
        var target = el.getAttribute("data-target");
        var el_target = document.querySelector(target);

        if (el_target) {
            h.addClass(el_target, "alert-notification-show");
        }
    }
}

bsml.addExtraComponents = function () {

    var html = '';
    var tmp = null;
    if (bsml.global.alert_system) {
        html = '' +
            '<div class="hide alert alert-danger alert-js"><button type="button" class="close" data-dismiss="alert" aria-label="Close">×</button><span data-locale-html="alert_client_error"></span></div>' +
            '<div class="hide alert alert-blocked alert-basic-support"><button type="button" class="close" data-dismiss="alert" aria-label="Close">×</button><span data-locale-html="alert_basic_support"></span></div>' +
            '<div class="hide alert alert-blocked alert-minimal-support"><button type="button" class="close" data-dismiss="alert" aria-label="Close">×</button><span data-locale-html="alert_minimal_support"></span></div>' +
            '<div class="hide alert alert-blocked alert-not-supported"><button type="button" class="close" data-dismiss="alert" aria-label="Close">×</button><span data-locale-html="alert_not_supported"></span></div>' +
            '<div class="hide alert alert-blocked alert-offline text-center"><span data-locale-html="alert_offline"></span></div>';

        tmp = document.createElement("div");
        tmp.innerHTML = html;
        bsml.global.alert_system.appendChild(tmp);
    }

    html = '' +
        '<div class="app-overlay">' +
        '<div class="loading">' +
        '<div class="sk-cube1 sk-cube"></div>' +
        '<div class="sk-cube2 sk-cube"></div>' +
        '<div class="sk-cube4 sk-cube"></div>' +
        '<div class="sk-cube3 sk-cube"></div>' +
        '<div class="loading-text"><span data-locale-html="loading"></span></div>' +
        '</div>' +
        '</div>';
    tmp = document.createElement("div");
    tmp.innerHTML = html;
    bsml.global.body.appendChild(tmp);


    html = '' +
        '<div class="goto-top hidden-minimal-support">' +
        '<a class="btn btn-info btn-rounded " onclick="window.scrollTo({top: 0,behavior: \'smooth\'});"><i class="ico-up-inverse">top</i></a>' +
        '</div>';
    tmp = document.createElement("div");
    tmp.innerHTML = html;
    bsml.global.body.appendChild(tmp);

}

bsml.controOfflineStatus = function () {
    if (!h.hasClass(bsml.global.body, "enable-offline")) {
        h.addEventListener(window, "online", bsml.checkOnlineStatus);
        h.addEventListener(window, "offline", bsml.checkOnlineStatus);
    }
}

bsml.toogleMenuLinkActive = function (e, el) {
    var active = document.querySelector(".menu .nav-link.active");
    if (active) {
        h.removeClass(active, "active");
    }
    h.addClass(el, "active");
}

bsml.resizeIframes = function () {

    var iframes = document.querySelectorAll(".embed-iframe");
    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        y = w.innerHeight || e.clientHeight || g.clientHeight;

    var ph = document.querySelector(".page-header"),
        pt = document.querySelector(".page-title"),
        phy = (ph && ph.clientHeight) || 0,
        pty = (pt && pt.clientHeight) || 0;


    h.forEach(iframes, function (i, iframe) {

        iframe.height = y - phy - pty;

        h.addClass(bsml.global.body, "no-scroll");

    });
}

//hide alert's on click 
bsml.dismissAlert = function (e, ev) {
    var box = ev.parentNode;
    if (h.hasClass(box, "alert")) {
        var container = box.parentNode;
        container.removeChild(box);
    }
}

bsml.setExpanded = function (selector) {

    if (h.getCookie("template-expand") == "1") {
        h.addClass(bsml.global.body, "template-expand");
        //chk.setAttribute("checked", "checked");
    } else {
        h.removeClass(bsml.global.body, "template-expand");
        //chk.removeAttribute("checked");
    }
}

//expand template
bsml.toggleExpand = function (e, el) {

    h.toggleClass(bsml.global.body, "template-expand") == "template-expand" ? h.setCookie("template-expand", "1") : h.removeCookie("template-expand");
}



//abre um modal com iframe para uma url
//size = 1,2,3,4,5 default 4, full screen
bsml.modalOpen = function (url, size) {

    if (size == null) size = 4;
    if (typeof url === 'object') url = url.getAttribute("data-href") != "" ? url.getAttribute("data-href") : url.getAttribute("href");

    var e = '';
    if (e) {
        e = event;
    } else {
        e = window.event;
    }


    var w = window.self;

    var getDomain = function(uri) {

        return ( uri.replace('http://','').replace('https://','').split(/[/?#]/)[0]);
    }

    var isRelative = function(uri) {
        return uri.toLowerCase().indexOf("http")<0;
    }

    var getTopFrame = function(init) {
        if (init == null) init = self;
        var found = false;
        var frame = init;
        if (frame != null && frame != top) {
            try {

                while (!found) {
                    if (frame != top
                        && frame.parent != null
                        && frame.parent.document != null
                        && frame.location != frame.parent.location) {
                        frame = frame.parent;
                    } else {
                        found = true;
                    }
                }
            } catch (e) { found = true; }
        }
        return frame;
    }

    var w = getTopFrame();

    if (e != null && (e.ctrlKey || e.metaKey)) {
        window.open(url);
    } else {
        var modal = w.document.getElementById("dynmodal");

        if (modal == null) {
            var html_main = '<div id="dynmodal" class="modal fade" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >' +
                '<div class="modal-dialog modal-dialog-centered modal-with-iframe" >' +
                '<div class="modal-content" >' +
                '</div>' +
                '</div>' +
                '</div>';
            var tmp = w.document.createElement("div");
            tmp.innerHTML = html_main;
            w.document.body.appendChild(tmp);
            modal = tmp.firstChild;
        }
        var modal_dialog = modal.querySelector(".modal-dialog");
        h.removeClass(modal_dialog, "modal-with-iframe-1");
        h.removeClass(modal_dialog, "modal-with-iframe-2");
        h.removeClass(modal_dialog, "modal-with-iframe-3");
        h.removeClass(modal_dialog, "modal-with-iframe-4");
        h.removeClass(modal_dialog, "modal-with-iframe-5");
        h.removeClass(modal, "left");
        h.addClass(modal_dialog, "modal-with-iframe-" + size);
        
        if (size == 4 || size == 5) {

            h.addClass(modal, "left");    
            if( isRelative(url) ||
                getDomain(url)==getDomain(document.location.href)) {    
                
                modal.setAttribute("data-original-url", document.location.href);
                
                history.replaceState(null, null, url);
                
            }
        }

        var html_iframe = ''
        html_iframe = '<div class="rainbow-progress-bar"></div>' +
            '<div style="position:absolute;top:5px;right:20px;background-color:#ffffff;padding:5px;"><button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>' +
            '<iframe class="modal-iframe"  onload="bsml.showModalFrame(this)" src="' + url + '" width="100%" height="100%" frameborder="0"></iframe>';


        var modal_instance = new w.Modal(modal, { content: html_iframe });
        modal_instance.show();


        modal.addEventListener('hidden.bs.modal', function(event){
            if(modal.getAttribute("data-original-url")!=null) {
                history.replaceState(null, null, modal.getAttribute("data-original-url"));
            }
          }, false);


    }
    return false;
}

bsml.showModalFrame = function (frame) {
    h.addClass(frame, "modal-iframe-show");
    h.addClass(document.querySelector(".rainbow-progress-bar"), "hide");
}

//toggle UI boxes
bsml.toggleBox = function (e, el) {
    var box = el.parentNode;
    var boxid = box.getAttribute("id") || "box_" + box.offsetTop;
    h.toggleClass(box, "box-closed") == "box-closed" ? h.setCookie(boxid, "1") : h.removeCookie(boxid);
}
//toggle UI boxes based on cookies
bsml.setOpenCloseBoxes = function () {

    h.forEach(document.querySelectorAll(".box-open-and-close"), function (i, box) {

        var boxid = box.getAttribute("id") || "box_" + box.offsetTop;

        if (h.getCookie(boxid) == "1") {
            h.addClass(box, "box-closed");
        } else {
            h.removeClass(box, "box-closed");
        }

    });
}

bsml.setAutosuggest = function(e, el) {
    //for each auto-suggest input in page
    h.forEach(document.querySelectorAll("[data-autosuggest-text]"), function (i, input) {


        var data_max_itens = input.getAttribute("data-autosuggest-maxitens") || 5;
        var data_min_length = input.getAttribute("data-autosuggest-minlength") || 2;
        var data_url = input.getAttribute("data-autosuggest-url") ;


        input.addEventListener("blur", function(e) {
            //check if any option was selected from de autosuggest box
            var selected = this.getAttribute("data-autosuggest-selected")?  this.getAttribute("data-autosuggest-selected") : false;
            //no option selected
            if(selected) {
                //empty the input field
                this.value = "";
                //check if has any input id field associated with
                var data_id_target = this.getAttribute("data-autosuggest-id-target-selector");
                if(data_id_target && document.querySelector(data_id_target)) {
                    //empty that field also
                    document.querySelector(data_id_target).value = "";
                }
            }
        });

        input.addEventListener("keypress",  function() {

            var data_length = input.value.length;

            //set the autosuggest input as it has not been selected any option from suggest box
            input.setAttribute("data-autosuggest-selected", false);

            if(data_url!=null) {

                //check if has an autosuggest box already created and associated with the input field. if not create it
                var container = input.parentNode.querySelector(".dropdown-menu-autosuggest");
                if(container==null) {
                    container = document.createElement("div");
                    container.className = "dropdown-menu dropdown-menu-autosuggest";    
                    container.style.maxWidth = "400px";
                    input.parentNode.appendChild(container);
                }else {
                    //if the box already exists, empty it
                    while(container.firstChild) {
                        container.removeChild(container.firstChild);
                    }
                }
                //hide the box
                h.removeClass(container, "show");

                //if the input field has the minium lenth to start searching
                if(data_min_length <= data_length) {

                    //ajax request to the data source
                    h.getJSON(data_url, 

                        function(success) {
                
                            var total_itens = 0;

                            //the json field used to compare the input field value
                            var data_compare = input.getAttribute("data-autosuggest-compare");
                            
                            //for each result from the ajax request
                            h.forEach(success, function(i, result) {

                                //get the json field value used in the comparisson from the ajax json record 
                                var data_to_compare = eval("result." + data_compare);

                                //compare the input field value with the comparisson json property value
                                if(data_to_compare.toLowerCase().indexOf(input.value.toLowerCase())>=0) {

                                    total_itens = total_itens + 1;
                                    
                                    if(total_itens <= data_max_itens) {


                                        //get the json property name used the fill the input field
                                        var data_text = input.getAttribute("data-autosuggest-text");
                                        //get the json property value used to fill the input field
                                        var data_text_value = data_text ? eval("result." + data_text) : "";
                                        //remove html tags
                                        data_text_value = data_text_value.replace(input.value, "<u>" + input.value + "</u>");

                                        //get the json property name used the fill the associated ID input field
                                        var data_id = input.getAttribute("data-autosuggest-id");
                                        //get the json property value used the fill the associated ID input field
                                        var data_id_value = data_id ? eval("result." + data_id) : "";

                                        //create the HTML option in the suggestion box
                                        var container_item = document.createElement("a");
                                        container_item.className ="dropdown-item text-ellipsis";
                                        container_item.innerHTML = data_text_value;
                                        container_item.setAttribute("href", "javascript:void(0)");
                                        container_item.setAttribute("data-id", data_id_value);
                                        
                                        //when the option is selected in the suggestion box
                                        container_item.onclick = function() {

                                            //
                                            input.value = data_text_value.replace(/<(?:.|\n)*?>/gm, '');
                                            input.setAttribute("data-autosuggest-selected", true);
                
                                            var data_id_target = input.getAttribute("data-autosuggest-id-target-selector");
                                            
                                            if(data_id_target && document.querySelector(data_id_target)) {

                                                document.querySelector(data_id_target).value = data_id_value;
                                            }

                                            h.removeClass(container, "show");
                                        }
                                        container.appendChild(container_item);
                                    }
                                    
                                    h.addClass(container, "show");
                                
                                }
                            });

                            if(container.childNodes.length==0) {
                                var container_item = document.createElement("a");
                                container_item.className ="dropdown-item";
                                container_item.innerHTML = "Não foi encontrado";
                                container.appendChild(container_item);
                                h.addClass(container, "show");
                            }

                        },
                        function(error) {

                        });
                }
            }
        });



    });

}


bsml.toggleSpinner = function (container, show) {

    if (container != null) {

        var spinner = container.querySelector(".spinner");
        if (spinner == null) {

            spinner = document.createElement("div");
            spinner.className = "spinner";
            container.appendChild(spinner);
        }

        if (show) {
            h.removeClass(spinner, "d-none");
        } else {
            h.addClass(spinner, "d-none");
        }
    }
}


//check if online
bsml.checkOnlineStatus = function () {

    navigator.onLine && h.hasClass(bsml.global.body, 'offline') ? h.removeClass(bsml.global.body, "offline") : "";
    !navigator.onLine && !h.hasClass(bsml.global.body, 'offline') ? h.addClass(bsml.global.body, "offline") : "";
}

//open overlay
bsml.openOverlay = function () {
    //if(h.isInIframe() && parent!=null && parent.bsml!=null && parent.bsml.openOverlay!=null) {
      //  parent.bsml.openOverlay();
    //}
    //else {
        h.addClass(bsml.global.body, "show-overlay");
    //}
}

//close overlay
bsml.closeOverlay = function () {
    
        h.removeClass(bsml.global.body, "show-overlay");
        h.removeClass(bsml.global.body, "menu-mobile-opened");
    
}

//toggle menu
bsml.toggleMenu = function () {
    h.toggleClass(bsml.global.body, "menu-mobile-opened");
    bsml.properties.closeOverlayOnClick = h.hasClass(bsml.global.body, "menu-mobile-opened");
}
//toggle search box (mobile)
bsml.toggleSearch = function () {
    h.toggleClass(bsml.global.body, "template-search-opened");
    document.querySelector("[type='search']").focus();
}


//show button gototop
bsml.showGoToTop = function () {
    var el = document.querySelector(".goto-top");
    if (!el) return;

    h.addEventListener(window, "scroll", function () {
        var wScrollCurrent = window.pageYOffset;
        if (wScrollCurrent > 0) {
            el.style.display = 'block';
        } else {
            el.style.display = 'none';
        }
    });
}

//apply style with querystring
bsml.applyStyleBasedOnQueryString = function (el, condition, css) {
    if (location.search.indexOf(condition) > 0) {
        h.addClass(el, css);
    }
}

//show or hide element depending on scroll position
bsml.setHideObjectOnScroll = function (el, csshide) {
    if (!h.isIE) {

        if (!el) return true;

        var elHeight = 0,
            elTop = 0,
            dHeight = 0,
            wHeight = 0,
            wScrollCurrent = 0,
            wScrollBefore = 0,
            wScrollDiff = 0;

        h.addEventListener(window, "scroll", function () {
            elHeight = el.offsetHeight;
            dHeight = document.body.offsetHeight;
            wHeight = window.innerHeight;
            wScrollCurrent = window.pageYOffset;
            wScrollDiff = wScrollBefore - wScrollCurrent;
            elTop = parseInt(window.getComputedStyle(el).getPropertyValue('top')) + wScrollDiff;

            if (wScrollCurrent <= 0) // scrolled to the very top; el sticks to the top
                h.removeClass(el, csshide);

            else if (wScrollDiff > 0) // scrolled up; el slides in
                h.removeClass(el, csshide);

            else if (wScrollDiff < 0) // scrolled down
            {
                if (wScrollCurrent + wHeight >= dHeight - elHeight)  // scrolled to the very bottom; el slides in
                    h.removeClass(el, csshide);
                else // scrolled down; el slides out
                    h.addClass(el, csshide);
            }

            wScrollBefore = wScrollCurrent;
        });
    }
}


bsml.convertIcons = function (el) {
    if (!el) el = document;
    if (!bsml.bootstrapPath) {
        bsml.bootstrapPath = h.getJsFilePath("orquestra-bootstrap.");
    }
    var sprite_path = bsml.bootstrapPath + "/../../icon/sprite/sprite.svg";
    var iconsProj = el.querySelectorAll("[class*=ico-]");
    for (var i = 0; i < iconsProj.length; i++) {
        var iconProj = iconsProj[i];

        var icons = iconProj.getAttribute('class').replace('ico-', '').split(' ')[0];
        if (icons == 'lg' || icons == 'md' || icons == 'sm' || icons == 'xl' || icons == '90p') {
            console.error("Erro: Chame primeiro a classe do ícone e após a classe de tamanho.");
        }
        var currentClass = iconProj.getAttribute('class');
        icons = icons.replace('-inverse', '');
        var div = document.createElement('div');
        div.innerHTML = '<svg class="' + currentClass + '"><use xlink:href="' + sprite_path + '#' + icons + '" /></svg>';
        iconProj.replaceWith(div.firstChild);
    }
}

//document ready
h.ready(function () {
    if (h.isIE) document.documentElement.className += " basic-support";
    document.documentElement.className = document.documentElement.className.replace("no-js", "js");
    bsml.init();
});


//polyfill for IE (all)
if (h.isIE) {
    h.addScript("vendor/bootstrap-native/polyfill.min.js");
    h.addScript("vendor/svgxuse/svgxuse.min.js");
}

//icones
bsml.convertIcons();