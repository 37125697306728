var tsml = {};
var resource = {
    pt : {
        "administration": "Administração",
        "main" : "Principal",
        "attention" : "Atenção",
        "home" : "Início",
        "menu" : "Menu",
        "open_menu":"Abrir menu",
        "close_menu":"Fechar menu",
        "expand_template":"Contrair",
        "add" : "Adicionar",
        "cancel" : "Cancelar",
        "back" : "Voltar",
        "back_help" : "Clique para voltar",
        "new_item": "Novo registro",
        "_new": "Novo",
        "new_help": "Clique para adicionar um novo item",
        "edit" : "Editar",
        "edit_help" : "Selecione um item e clique para editá-lo",
        "edit_need_check": "Para <b>editar</b> um item, é preciso selecionar uma linha da tabela antes.",
        "delete":"Excluir",
        "delete_help":"Selecione um item e clique para excluí-lo",
        "delete_help_2":"Excluir esse registro",
        "delete_need_check":"Para <b>excluir</b> um item, é preciso selecionar uma linha da tabela antes.",
        "delete_confirm" : "Confirmo a exclusão",
        "delete_confirm_text" : "Você <b>tem certeza</b> de que deseja excluir o(s) item(ns) selecionado(s)? Essa operação é irreversível. ",
        "search_continuous": "pesquisar...",
        "search": "Pesquisar",
        "search_help": "Clique para pesquisar itens da tabela abaixo",
        "clear": "Limpar",
        "clear_help": "Clique para limpar o formulário de pesquisa",
        "all" : "tudo",
        "change_language":"Alterar idioma",
        "notifications":"Notificações",
        "logout" : "Sair",
        "marketplace" : "Marketplace",
        "configs" : "Configurações",
        "personal_configs" : "Configurações pessoais",
        "change_user" : "Alterar usuário",
        "help" : "Central de ajuda",
        "foruns" : "Fóruns",
        "check_all": "Clique para selecionar todos os itens dessa página",
        "order_column" : "Ordernar por essa coluna",
        "page_first" : "Primeira página",
        "page_previous" : "Página anterior",
        "page_next" : "Próxima página",
        "page_last" : "Última página",
        "first" : "Primeira",
        "previous" : "Anterior",
        "next" : "Próxima",
        "last" : "Última", 
        "itens" : "Registros", 
        "page" : "Página", 
        "password": "Senha",
        "do_you_need_help" : "Você precisa de ajuda?",
        "ok_understand" : "OK, entendi",
        "welcome_to": "Bem vindo ao",
        "remember_data": "Lembrar meus dados" ,
        "forgot_password": "Esqueci minha senha",
        "enter": "Entrar",
        "dont_have_account" : "Não tem conta?",
        "create_one":"Criar uma",
        "alert_client_error" : "Ocorreu um erro na interação com o seu navegador. ",
        "alert_basic_support" : "Você está usando o Internet Explorer 10+ ou outro navegador antigo, desatualizado e com problemas de segurança. Para ter uma experiência de uso completa e segura  utilize Microsoft EDGE,  Google Chrome,  Mozilla Firefox ou Safari.",
        "alert_minimal_support" : "Você está usando o Internet Explorer 8 ou 9 ou outro navegador antigo, desatualizado e com problemas de segurança. Para ter uma experiência de uso completa e segura  utilize Microsoft EDGE,  Google Chrome,  Mozilla Firefox ou Safari.",
        "alert_not_supported" : "Você está usando o Internet Explorer 7 ou inferior, um navegador antigo, desatualizado e com problemas de segurança. Para ter uma experiência de uso completa e segura  utilize Microsoft EDGE,  Google Chrome,  Mozilla Firefox ou Safari.",
        "alert_not_found" : "Ops...não entramos nenhum registro aqui.",
        "alert_offline" : "Offline",
        "loading" : "Carregando" ,
        "see_all" : "Ver todas",
        "mark_as_read":"Marcar todas como lidas",
        "new_notification":"Nova notificação",
        "notification_received":"Recebidas",
        "notification_sent":"Enviadas",
        "preferences":"Preferências",
        "repos" : "Bibliotecas",
        "advanced" : "Avançado",
        "terms": "Termos de uso",
        "terms_privacy":"Privacidade e segurança",
        "terms_technical":"Nível de serviço",
        "api":"Documentação de APIs",
        "changelog":"Novidades da versão",
        "ead":"Universidade EAD",
        "content_library":"Material educativo",
        "zapier":"Integração via Zapier",
        "tour":"Tour interativo"
    },
    en : {
        "administration": "Administration",
        "main" : "Main",
        "attention": "Attention",
        "home": "Home",
        "menu": "Menu",
        "open_menu": "Open menu",
        "close_menu": "Close menu",
        "expand_template": "Contract",
        "add": "Add",
        "cancel": "Cancel",
        "back": "Back",
        "back_help": "Click to go back",
        "new_item": "New record",
        "_new": "New",
        "new_help": "Click to add a new item",
        "edit": "Edit",
        "edit_help": "Select an item and click to edit it",
        "edit_need_check": "To <b> edit </ b> an item, you must select a table row before.",
        "delete": "Delete",
        "delete_help": "Select an item and click to delete it",
        "delete_help_2": "Delete this record",
        "delete_need_check": "For <b> delete </ b> an item, you must select a table row before.",
        "delete_confirm": "I confirm the deletion",
        "delete_confirm_text": "Are you <b> sure </ b> that you want to delete the selected item (s)? This operation is irreversible.",
        "search_continuous": "search ...",
        "search": "Search",
        "search_help": "Click to search for items from the table below",
        "clear": "Clear",
        "clear_help": "Click to clear the search form",
        "all": "all",
        "change_language": "Change language",
        "notifications": "Notifications",
        "logout": "Log out",
        "marketplace": "Marketplace",
        "configs": "Settings",
        "personal_configs": "Personal settings",
        "change_user": "Change user",
        "help": "Help center",
        "Forums": "Forums",
        "check_all": "Click to select all items on this page",
        "order_column": "Order by this column",
        "page_first": "First page",
        "page_previous": "Previous page",
        "page_next": "Next page",
        "page_last": "Last page",
        "first": "First",
        "previous": "Previous",
        "next": "Next",
        "last": "last",
        "items": "Records",
        "page": "Page",
        "password": "Password",
        "do_you_need_help": "Do you need help?",
        "ok_understand": "Okay, I got it",
        "welcome_to": "Welcome to",
        "remember_data": "Remember my data",
        "forgot_password": "I forgot my password",
        "enter": "Enter",
        "dont_have_account": "No account?",
        "create_one": "Create one",
        "alert_client_error": "There was an error interacting with your browser.",
        "alert_basic_support": "You are using Internet Explorer 10+ or ​​another old, out-of-date browser with security issues.For a full and secure use experience use Microsoft EDGE, Google Chrome, Mozilla Firefox or Safari.",
        "alert_minimal_support": "You are using Internet Explorer 8 or 9 or another old, out-of-date browser with security issues.For a full and secure use experience use Microsoft EDGE, Google Chrome, Mozilla Firefox or Safari.",
        "alert_not_supported": "You are using Internet Explorer 7 or lower, an old, out-of-date browser with security issues.For a full and secure use experience use Microsoft EDGE, Google Chrome, Mozilla Firefox or Safari.",
        "alert_not_found": "Oops ... we did not enter any record here.",
        "alert_offline": "Offline",
        "loading": "Loading" ,
        "see_all" : "View all",
        "mark_as_read":"Mark as read",
        "new_notification":"New notification",
        "notification_received":"Received",
        "notification_sent":"Sent",
        "preferences":"Preferences",
        "repos" : "Libraries",
        "advanced" : "Advanced",
        "terms": "Terms of use",
        "terms_privacy":"Privacy and security",
        "terms_technical":"Service agreement",
        "api":"API Documentation",
        "changelog":"What's new",
        "ead":"University",
        "content_library":"Education library",
        "zapier":"Integration via Zapier",
        "tour":"Interactive tour"          
    },
    es : {
        "administration": "Administración",
        "main" : "Principal",
        "attention": "Atención",
        "home": "Inicio",
        "menu": "Menú",
        "open_menu": "Abrir menú",
        "close_menu": "Cerrar menú",
        "expand_template": "Contrato",
        "add": "Agregar", 
        "cancel": "Cancelar",
        "back": "Volver",
        "back_help": "Haga clic para volver", 
        "new_item": "Nuevo registro",
        "_new": "Nuevo",
        "new_help": "Haga clic para agregar un nuevo elemento",
        "edit": "Editar",
        "edit_help": "Seleccione un elemento y haga clic para editarlo",
        "edit_need_check": "Para editar un elemento, debe seleccionar una fila de la tabla antes.",
        "delete": "Borrar",
        "delete_help": "Seleccione un elemento y haga clic para eliminarlo",
        "delete_help_2": "Eliminar este registro",
        "delete_need_check": "Para eliminar un elemento, debe seleccionar una fila de la tabla antes.",
        "delete_confirm": "Confirmo la exclusión",
        "delete_confirm_text": "Usted <b> está seguro de que desea eliminar el (los) elemento (s) seleccionado (s)? Esta operación es irreversible.",
        "search_continuous": "buscar ...", 
        "search": "Buscar",
        "search_help": "Haga clic para buscar elementos de la tabla siguiente",
        "clear": "Limpiar", 
        "clear_help": "Haga clic para borrar el formulario de búsqueda",
        "all": "todo",
        "change_language": "Cambiar idioma",
        "notifications": "Notificaciones",
        "logout": "Salir",
        "mercado": "mercado", 
        "configs": "Configuración",
        "personal_configs": "Configuración personal",
        "change_user": "Cambiar usuario",
        "help": "Central de ayuda",
        "foruns": "Foros",
        "check_all": "Haga clic para seleccionar todos los elementos de esa página",
        "order_column": "Ordenar por esa columna",
        "page_first": "Primera página",
        "page_previous": "Página anterior",
        "page_next": "Página siguiente",
        "page_last": "Última página", 
        "first": "Primera", 
        "previous": "Anterior",
        "next": "Siguiente", 
        "last": "Última",
        "itens": "Registros",
        "page": "Página", 
        "password": "Contraseña",
        "do_you_need_help": "¿Necesita ayuda?",
        "ok_understand": "OK, entendí", 
        "welcome_to": "Bienvenido al", 
        "record_data": "Recordar mis datos",
        "forgot_password": "He olvidado mi contraseña",
        "enter": "Entrar",
        "dont_have_account": "¿No tienes cuenta?",
        "create_one": "Crear una",
        "alert_client_error": "Se ha producido un error en la interacción con su navegador.",
        "alert_basic_support": "Usted está usando Internet Explorer 10+ u otro navegador antiguo, anticuado y con problemas de seguridad. Para tener una experiencia de uso completo y seguro utilice Microsoft EDGE, Google Chrome, Mozilla Firefox o Safari.",
        "alert_minimal_support": "Usted está utilizando Internet Explorer 8 o 9 u otro navegador antiguo, anticuado y con problemas de seguridad. Para tener una experiencia de uso completo y seguro utilice Microsoft EDGE, Google Chrome, Mozilla Firefox o Safari.",
        "alert_not_supported": "Usted está utilizando Internet Explorer 7 o inferior, un navegador antiguo, anticuado y con problemas de seguridad. Para tener una experiencia de uso completo y seguro utilice Microsoft EDGE, Google Chrome, Mozilla Firefox o Safari.",
        "alert_not_found": "Ops ... no entra ningún registro aquí.",
        "alert_offline": "Offline,",
        "loading": "Cargando",
        "see_all" : "Ver todas",
        "mark_as_read":"Marcar como leídas",
        "new_notification":"Nueva notificación",
        "notification_received":"Recibido",
        "notification_sent":"Expedido",
        "preferences":"Preferencias",
        "repos" : "Bibliotecas",
        "advanced" : "Avanzado" ,
        "terms": "Terminos de uso",
        "terms_privacy":"Privacidad y seguridad",
        "terms_technical":"Nivel de servicio",
        "api":"Documentación API",
        "changelog":"Qué hay de nuevo",
        "ead":"Universidad",
        "content_library":"Biblioteca educativa",
        "zapier":"Integración vía Zapier",
        "tour":"Tour interactivo"                   
    }
};

tsml.translate = function(container) {

    var elements = container.querySelectorAll("[data-locale-title], [data-locale-html], [data-locale-placeholder]");    
    h.forEach(elements, function(item, el) {

        var html_tag_lang = (document.querySelector("html").getAttribute("lang") || "pt").replace("-", "_").toLowerCase();
        
        var locale_title = el.getAttribute("data-locale-title");
        var locale_html = el.getAttribute("data-locale-html");
        var locale_placeholder = el.getAttribute("data-locale-placeholder");

        if(locale_title!=null && locale_title!="") {
            locale_title = tsml.adjustForbiddenKeys(locale_title);
            var resource_title = eval("resource."+html_tag_lang+"." + locale_title);  
            if(resource_title) {
                el.setAttribute("title", resource_title);
            }
        }

        if(locale_html!=null && locale_html!="") {
            locale_html = tsml.adjustForbiddenKeys(locale_html);
            var resource_html = eval("resource."+html_tag_lang+"." + locale_html);  
            if(resource_html) {
                el.innerHTML = resource_html;
            }
        }

        if(locale_placeholder!=null && locale_placeholder!="") {
            locale_placeholder = tsml.adjustForbiddenKeys(locale_placeholder);
            var resource_placeholder = eval("resource."+html_tag_lang+"." + locale_placeholder);  
            if(resource_placeholder) {
                el.setAttribute("placeholder", resource_placeholder);
            }
        }

    });
};

tsml.adjustForbiddenKeys = function(key) {
    if("new".indexOf(key)>=0) {
        return "_"+ key;
    }
    else
    {
        return key;
    }
}